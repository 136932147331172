import {
  Links,
  LiveReload,
  Scripts,
  ScrollRestoration } from
"@remix-run/react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";

type Props = {
  children: React.ReactNode;
  isPreview?: boolean;
  ENV?: string | undefined;
};

const Document = ({ children, isPreview, ENV }: Props) =>
<ThemeProvider theme={theme}>
    <html lang="en">
      <head>
        <title>{"KptnCook CMS"}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {isPreview &&
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
        integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
        crossOrigin="anonymous" />

      }
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <script
        dangerouslySetInnerHTML={{
          __html: `window.env = ${JSON.stringify(ENV)}`
        }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>
  </ThemeProvider>;


export default Document;